import { useContext } from 'react';

import {SceneContext} from '../Scene/SceneContext';

import './Toolbar.css';


const Toolbar = () => {
  const scene = useContext(SceneContext);

  const onNumberOfFlorsChange = (e) => {
    const { value } = e.target;
    scene.setNumberOfFlors(value)
  }

  const onIsSlabTransparentChange = (e) => {
    const { checked } = e.target;
    scene.setIsSlabTransparent(checked)
  }

  const onSlabWidthChange = (e) => {
    const { value } = e.target;
    scene.setSlabWidth(value)
  }

  const onSlabDepthChange = (e) => {
    const { value } = e.target;
    scene.setSlabDepth(value)
  }

  const slabInfo ={
    minWidth: 1000,
    maxWidth: 3000,
    minDepth: 600,
    maxDepth: 1800
  }

  return (
    <div className="Toolbar p-3">
      <div className="divider my-2 ">Number of floors</div>
      <div className="control is-flex is-justify-content-center">
        <label className="radio">
          <input type="radio" name="answer" value="1" onChange={onNumberOfFlorsChange}/>
          <span className="px-2">1</span>
        </label>
        <label className="radio">
          <input type="radio" name="answer" value="2" onChange={onNumberOfFlorsChange}/>
          <span className="px-2">2</span>
        </label>
        <label className="radio">
          <input type="radio" name="answer" value="3" onChange={onNumberOfFlorsChange}/>
          <span className="px-2">3</span>
        </label>
      </div>

      <div className="divider my-2">Slab size</div>
      Width <span className="is-size-7">(from {slabInfo.minWidth} to {slabInfo.maxWidth}). Current {scene.slabWidth}</span>
      <input 
        className="slider is-fullwidth is-medium is-danger is-circle" 
        step="100" 
        min={slabInfo.minWidth} 
        max={slabInfo.maxWidth}
        defaultValue={scene.slabWidth}
        type="range"
        onChange={onSlabWidthChange}
      / >

      Depth <span className="is-size-7">(from {slabInfo.minDepth} to {slabInfo.maxDepth}). Current {scene.slabDepth}</span>
      <input 
        className="slider is-fullwidth is-medium is-danger is-circle" 
        step="100" 
        min={slabInfo.minDepth}
        max={slabInfo.maxDepth}
        defaultValue={scene.slabDepth}
        type="range"
        onChange={onSlabDepthChange}
        / >

      <div className="divider my-2">Concrete material</div>

      <div className="columns">
        <div className="column is-flex is-justify-content-center">
        <div 
          onClick={() => scene.setConcreteMaterial("concrete_0")} 
          className={`Toolbar-thumbnail ${ scene.concreteMaterial === "concrete_0" ? "Toolbar-thumbnail-selected" : ""}`}
          style={
          {
            backgroundImage: 'url("/Assets/textures/concrete_0.jpg")'
          }
        }></div>
        </div>
        <div className="column is-flex is-justify-content-center">
          <div 
            onClick={() => scene.setConcreteMaterial("concrete_1")} 
            className={`Toolbar-thumbnail ${ scene.concreteMaterial === "concrete_1" ? "Toolbar-thumbnail-selected" : ""}`}
            style={
            {
              backgroundImage: 'url("/Assets/textures/concrete_1.jpg")'
            }
          }></div>
        </div>
      </div>
      {/* <div className="divider my-2">Steel material</div>
      <div className="columns">
        <div className="column is-flex is-justify-content-center">
        <div 
          className={`Toolbar-thumbnail ${ scene.steelMaterial === "steel_0" ? "Toolbar-thumbnail-selected" : ""}`}
          onClick={() => scene.setSteelMaterial("steel_0")}
          style={
              {
                backgroundImage: 'url("/Assets/textures/steel_0.jpg")'
              }
            }></div>
        </div>
        <div className="column is-flex is-justify-content-center">
          <div 
            className={`Toolbar-thumbnail ${ scene.steelMaterial === "steel_1" ? "Toolbar-thumbnail-selected" : ""}`}
            onClick={() => scene.setSteelMaterial("steel_1")}
            style={
                {
                  backgroundImage: 'url("/Assets/textures/steel_1.jpg")'
                }
              }></div>
          </div>
        <div className="column is-flex is-justify-content-center">
        <div 
          className={`Toolbar-thumbnail ${ scene.steelMaterial === "steel_2" ? "Toolbar-thumbnail-selected" : ""}`}
          onClick={() => scene.setSteelMaterial("steel_2")}
          style={
              { 
                backgroundImage: 'url("/Assets/textures/steel_2.jpg")'
              }
            }></div>
        </div>
      </div> */}


      <div className="divider my-2">Slab transparency</div>
      <label className="checkbox">
        <input type="checkbox" onChange={onIsSlabTransparentChange}/>
        <span className="px-2">
          Transparent
        </span>
      </label>    
    </div>
    
  )
}

export default Toolbar