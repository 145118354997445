import { createContext, useState } from "react";

const SceneContext = createContext();

const SceneProvider = ({ children }) => {
  const [numberOfFlors, setNumberOfFlors] = useState(2);
  const [slabWidth, setSlabWidth] = useState(2000);
  const [slabDepth, setSlabDepth] = useState(1200);
  const [concreteMaterial, setConcreteMaterial] = useState("concrete_0");
  const [steelMaterial, setSteelMaterial] = useState("steel_0");
  const [isSlabTransparent, setIsSlabTransparent] = useState(false);
    
  return (
    <SceneContext.Provider value={
      { 
        numberOfFlors,
        slabWidth,
        slabDepth,
        concreteMaterial,
        steelMaterial,
        isSlabTransparent,
        setSlabDepth,
        setSlabWidth,
        setNumberOfFlors,
        setSteelMaterial,
        setConcreteMaterial,
        setIsSlabTransparent
      }}>
      {children}
    </SceneContext.Provider>
  );
};

export {SceneContext, SceneProvider}