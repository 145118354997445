import Scene from './Scene/Scene';
import { SceneProvider } from './Scene/SceneContext';
import Toolbar from './UI/Toolbar';
import DebugPortlet from './UI/DebugPortlet';

import 'bulma/css/bulma.min.css';
import '@creativebulma/bulma-divider/dist/bulma-divider.min.css'
import 'bulma-slider/dist/css/bulma-slider.min.css'
import 'bulma-slider/dist/js/bulma-slider.js';

import logo from './logo.svg';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <header>
        <img src={logo} className="App-logo" alt="logo" />
      </header>
        <SceneProvider>
          <Scene />
          <Toolbar />
          {/* <DebugPortlet /> */}
        </SceneProvider>

    </div>
  );
}

export default App;
