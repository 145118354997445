import React, { Component } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import GLTFLoader from 'three-gltf-loader';
import { loadTexture } from '../Utils/utils'
import { RedFormat } from "three";
import { SceneContext } from "./SceneContext";
// const UserContext = React.createContext()

class Scene extends Component {
  static contextType = SceneContext;
  constructor(props) {
    super(props);
    this.state = {};
    this.start = this.start.bind(this);
    this.gltf = null;
    this.stop = this.stop.bind(this);
    this.animate = this.animate.bind(this);
    this.populateScene = this.populateScene.bind(this);
    this.renderScene = this.renderScene.bind(this);
    this.computeBoundingBox = this.computeBoundingBox.bind(this);
    this.setupScene = this.setupScene.bind(this);
    this.destroyContext = this.destroyContext.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.materials = null;
    this.floor1 = new THREE.Group();
    this.floor2 = new THREE.Group();
    this.floor3 = new THREE.Group();
    this.roof = new THREE.Group();
    this.floor2.visible = false;
    this.floor3.visible = false;
    this.state = {
      floors:1,
      concreteMat:'concrete_0',
      steelMat:'steel_0',
      transparent:true
    }
  }

  UNSAFE_componentWillMount() {
    window.addEventListener("resize", this.handleWindowResize);
  }

  componentDidMount() {
    this.setupScene();
  }

  componentDidUpdate() {
    // console.log(this.context)
    // console.log(this.context.isSlabTransparent)

    this.scene.traverse(obj => {
      switch(obj.name){
        case 'BeamLong_F':
          obj.scale.z = this.context.slabWidth/100;
          obj.position.z = this.context.slabDepth/100;
        break;
        case 'BeamLong_B':
          obj.scale.z = this.context.slabWidth/100;
          obj.position.z = 0.8;
        break;
        case 'BeamLongRoof_F':
          obj.scale.z = this.context.slabWidth/100 + 2.2;
          obj.position.z = this.context.slabDepth/100 + 0.5;
        break;
        case 'BeamLongRoof_B':
          obj.scale.z = this.context.slabWidth/100 + 2.2;
          obj.position.z = 0.8;
        break;
        case 'Floor':
          obj.scale.z = this.context.slabDepth/100 - 0.5;
          obj.scale.x = this.context.slabWidth/100;
            if(this.context.concreteMaterial === 'concrete_1'){
              obj.material = this.materials.concrete1;
            } else {
              obj.material = this.materials.concrete;
            }
            if(this.context.isSlabTransparent === true){
                obj.material.opacity = 0.5; 
              } else {
                obj.material.opacity = 1; 
              }
        break;
        case 'Column_LB':
          obj.position.x = this.context.slabWidth/200;
        break;
        case 'Column_RB':
          obj.position.x = -this.context.slabWidth/200;
        break;
        case 'Beam_L':
          obj.position.x = -this.context.slabWidth/200;
          obj.scale.z = this.context.slabDepth/100 - 0.2;
        break;
        case 'Beam_R':
          obj.position.x = this.context.slabWidth/200;
          obj.scale.z = this.context.slabDepth/100 - 0.2;
        break;
        case 'BeamRoof_L':
          obj.position.x = -this.context.slabWidth/200 - 1.2;
          obj.scale.z = this.context.slabDepth/100 + 0.2;
        break;
        case 'BeamRoof_R':
          obj.position.x = this.context.slabWidth/200 + 1.2;
          obj.scale.z = this.context.slabDepth/100 + 0.2;
        break;
        case 'Column_LF':
          obj.position.x = this.context.slabWidth/200;
          obj.position.z = this.context.slabDepth/100 - 2;
        break;
        case 'Column_RF':
          obj.position.x = -this.context.slabWidth/200;
          obj.position.z = this.context.slabDepth/100 - 2;
        break;
        case 'BeamSmall_L':
          obj.position.x = -this.context.slabWidth/500;
          obj.scale.z = this.context.slabDepth/200 - 0.5;
        break;
        case 'BeamSmall_R':
          obj.position.x = this.context.slabWidth/500;
          obj.scale.z = this.context.slabDepth/200 - 0.5;
        break;
        case 'BeamSmall_B':
          if(this.context.slabWidth < 1500){
            obj.visible = false
          } else {
            obj.visible = true
          }
          if(this.context.slabWidth < 2000){
            obj.position.x = 0
          } else {
            obj.position.x = -this.context.slabWidth/500;
          }
          obj.scale.z = this.context.slabDepth/200 - 0.5;
        break;
        case 'BeamSmall_F':
          if(this.context.slabWidth < 2000){
            obj.visible = false
          } else {
            obj.visible = true
          }
          obj.position.x = this.context.slabWidth/500;
          obj.scale.z = this.context.slabDepth/200 - 0.5;
        break;
        case 'RoofGlass':
          obj.scale.z = this.context.slabDepth/100;
          obj.scale.x = this.context.slabWidth/100 + 2;
        break;
        case 'Rail_F':
          obj.scale.x = this.context.slabWidth/100 + 0.5;
          obj.position.x = -this.context.slabWidth/200 - 0.25;
          obj.position.z = this.context.slabDepth/100;
        break;
        case 'Rail_L':
          obj.scale.x = this.context.slabDepth/100;
          obj.position.x = -this.context.slabWidth/200 - 0.25;
          obj.position.z = this.context.slabDepth/100;
        break;
        case 'Rail_R':
          obj.scale.x = this.context.slabDepth/100;
          obj.position.x = this.context.slabWidth/200 + 0.25;
          obj.position.z = this.context.slabDepth/100;
        break;
        case 'RailVert':
          obj.position.z = this.context.slabDepth/100;
        break;
        case 'Net':
          obj.scale.z = this.context.slabDepth/100 - 2;
          obj.scale.x = this.context.slabWidth/100 - 2;
          obj.position.y = .5;
        break;
        default:
          const aName = obj.name.split('_');
          if(aName[0] === 'Baluster'){
            obj.position.z = this.context.slabDepth/100;
            obj.position.x = 1 * parseInt(aName[1]) - this.context.slabWidth/200 - 0.2;
            if(aName[1] > this.context.slabWidth/100){
              obj.visible = false;
            } else {
              obj.visible = true;
            }
          }
          if(aName[0] === 'BalusterL'){
            obj.position.x = -this.context.slabWidth/200 - 0.2;
            if(aName[1] > this.context.slabDepth/100){
              obj.visible = false;
            } else {
              obj.visible = true;
            }
          }
          if(aName[0] === 'BalusterR'){
            obj.position.x = this.context.slabWidth/200 + 0.2;
            if(aName[1] > this.context.slabDepth/100){
              obj.visible = false;
            } else {
              obj.visible = true;
            }
          }
        break;
      }
      // console.log(this.context.steelMaterial,obj.material)
      // if(obj.material){
      //   if(obj.material.name === 'Silver'){
      //   switch(this.context.steelMaterial){
      //     case 'steel_0':
      //       obj.material.color = '0xff0000';
      //     break;
      //     case 'steel_1':
      //       obj.material.color = '0x00ff00';
      //     break;
      //     case 'steel_2':
      //       obj.material.color = '0x0000ff';
      //     break;
          
      //   }

      //   }
      // }

    });

    if(this.state.floors !== this.context.numberOfFlors){
      switch(this.context.numberOfFlors){
        case '1':
          this.floor2.visible = false;
          this.floor3.visible = false;
          this.controls.target.y = 10;
          this.camera.position.z = 50;
          this.roof.position.y = 29;
        break;
        case '2':
          this.floor2.visible = true;
          this.floor3.visible = false;
          this.controls.target.y = 30;
          this.camera.position.z = 70;
          this.roof.position.y = 59;
        break;
        case '3':
          this.floor2.visible = true;
          this.floor3.visible = true;
          this.controls.target.y = 50;
          this.camera.position.z = 100;
          this.roof.position.y = 89;
        break;
      }
      this.state.floors = this.context.numberOfFlors   
    }


  }

  setupScene() {
    this.width = this.container.clientWidth;
    this.height = this.container.clientHeight;
    
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.shadowMap.enabled = true;
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    this.canvas = null;
    
    let scene = new THREE.Scene();
    // scene.background = new THREE.Color("transparent");
    // scene.background = transparent;

    let camera = new THREE.PerspectiveCamera(
      60,
      this.width / this.height,
      0.25,
      1000
    );
    scene.add(camera);
    
    const metals = {
      SRE: new THREE.MeshPhongMaterial({
        color: 0xc0c0c0,
        shininess: 50,
        specular: 0xd0d0d0,
      }),
      BRJ: new THREE.MeshPhongMaterial({
        color: 0xaf9c88,
        shininess: 50,
        specular: 0xffffff,
      }),
      SRAN: new THREE.MeshPhongMaterial({
        color: 0x505050,
        shininess: 80,
      }),
    };

    const brickTexture = new THREE.TextureLoader().load("/Assets/textures/Wall_BrickRed_albedo.jpg");
    brickTexture.wrapS = THREE.RepeatWrapping;
    brickTexture.wrapT = THREE.RepeatWrapping;

    const brickNormalTexture = new THREE.TextureLoader().load("/Assets/textures/Wall_BrickRed_normal.jpg")
    brickNormalTexture.wrapS = THREE.RepeatWrapping;
    brickNormalTexture.wrapT = THREE.RepeatWrapping;

    this.concrete_0 = new THREE.TextureLoader().load("/Assets/textures/ConcreteNew0012.jpg")
    this.concrete_0.wrapS = THREE.RepeatWrapping;
    this.concrete_0.wrapT = THREE.RepeatWrapping;

    this.concrete_1 = new THREE.TextureLoader().load("/Assets/textures/concrete_1.jpg")
    this.concrete_1.wrapS = THREE.RepeatWrapping;
    this.concrete_1.wrapT = THREE.RepeatWrapping;

    const reflection = new THREE.TextureLoader().load("/Assets/textures/reflection_ocean.jpg")

    this.materials = {
      metals:metals,
      brick:new THREE.MeshPhongMaterial({
        map: brickTexture,
        normalMap:brickNormalTexture,
      }),
      concrete:new THREE.MeshPhongMaterial({map:this.concrete_0, transparent:true, opacity: 1}),
      concrete1:new THREE.MeshPhongMaterial({map:this.concrete_1, transparent:true, opacity: 1}),
      glass:new THREE.MeshPhongMaterial({
        color:0xc0c0c0,
        transparent: true, 
        opacity: 0.5,
        envMap:reflection,
        envMapIntensity:100
      }),
      frame:new THREE.MeshPhongMaterial({color:0x050505})
    }

    // loadTexture(`/Assets/textures/Wall_BrickRed_albedo.jpg`).then(texture => {
    //   const shadowMaterial = new THREE.MeshBasicMaterial({
    //     map: texture,
    //   });
    //   this.populateScene();
    // })

    let sphere = new THREE.BoxGeometry(100, 100, 100);
    let material = new THREE.MeshPhongMaterial({
      // map: new THREE.TextureLoader().load("/Assets/2_no_clouds_4k.jpg"),
      // bumpMap: new THREE.TextureLoader().load("/Assets/elev_bump_4k.jpg"),
      // bumpScale: 0.005,
      // specularMap: new THREE.TextureLoader().load("/Assets/water_4k.png"),
      // specular: new THREE.Color("grey"),
    });
    let mesh = new THREE.Mesh(sphere, material);
    // // scene.add(mesh);
    // sphere = new THREE.SphereGeometry(50.1, 300, 300);
    // material = new THREE.MeshPhongMaterial({
    //   map: new THREE.TextureLoader().load("/Assets/fair_clouds_4k.png"),
    //   transparent: true,
    // });
    // mesh = new THREE.Mesh(sphere, material);
    // // scene.add(mesh);

    this.gltfLoader = new GLTFLoader();
    this.gltfLoader.setCrossOrigin('anonymous');
    this.gltfLoader.load(
      `/Assets/Building/Building.glb`,
      gltf => {
        // this.loaded = true;
        this.gltf = gltf;
        this.populateScene(this.gltf);
        // this.preloadTextures();
      },
      xhr => {
        // called while loading is progressing
        let progres = xhr.total ? (xhr.loaded / xhr.total) * 100 : 100;
      },
      error => {
        // called when loading has errors
        console.error('An error happened', error);
      },
    );

    // const loader = new OBJLoader();

    // load a resource
    // loader.load(
    //   // resource URL
    //   'Assets/Building/Test2.obj',
    //   // called when resource is loaded
    //   function ( object ) {

    //     scene.add( object );

    //   },
    //   // called when loading is in progresses
    //   function ( xhr ) {

    //     console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );

    //   },
    //   // called when loading has errors
    //   function ( error ) {

    //     console.log( 'An error happened' );

    //   }
    // );

    this.renderer = renderer;
    this.scene = scene;
    this.camera = camera;
    this.object = mesh;

    let spotLight = new THREE.SpotLight(0xffffff, 0.5);
    spotLight.position.set(45, 50, 15);
    camera.add(spotLight);
    this.spotLight = spotLight;

    let spotLight2 = new THREE.SpotLight(0xffffff, 0.8);
    spotLight2.position.set(-45, -50, 15);
    camera.add(spotLight2);
    this.spotLight2 = spotLight2;

    let ambLight = new THREE.AmbientLight(0xa0a0a0);
    // ambLight.intensity = 2;
    ambLight.position.set(5, 3, 5);
    this.camera.add(ambLight);

    this.computeBoundingBox();
  }

  populateScene(gltf){
    if(this.gltf.scene !== undefined){
      this.gltf.scene.traverse(object => {
        if(object.type === 'Mesh'){       
          object.scale.set(10,10,10)
          switch (object.name) {
            case 'Wall':
              object.material = this.materials.brick;
              this.floor1.add(object.clone());          
              this.floor2.add(object.clone());          
              this.floor3.add(object.clone());          
            break;
            case 'DoorFrame':
              // object.material = this.materials.concrete;
              this.floor1.add(object.clone());          
              this.floor2.add(object.clone());          
              this.floor3.add(object.clone());          
            break;
            case 'Frame':
              // object.material = this.materials.concrete;
              this.floor1.add(object.clone());          
              this.floor2.add(object.clone());          
              this.floor3.add(object.clone());          
            break;
            case 'Glass':
              object.material = this.materials.glass;
              this.floor1.add(object.clone());          
              this.floor2.add(object.clone());          
              this.floor3.add(object.clone());          
            break;
            case 'Floor':
              object.scale.z = this.context.slabDepth/100;
              object.scale.x = this.context.slabWidth/100;
              this.floor1.add(object.clone());          
              this.floor2.add(object.clone());          
              this.floor3.add(object.clone());
              object.material = this.materials.concrete;         
            break;
            case 'BeamLong':
              object.name = "BeamLong_F";
              object.scale.z = this.context.slabWidth/100;
              object.position.z = this.context.slabDepth/100 + 0.15;
              this.floor1.add(object.clone());          
              this.floor2.add(object.clone());          
              this.floor3.add(object.clone());        
              object.name = "BeamLong_B";
              object.scale.z = this.context.slabWidth/100;
              object.position.z = 0.8;
              this.floor1.add(object.clone());          
              this.floor2.add(object.clone());          
              this.floor3.add(object.clone());  
              object.name = "BeamLongRoof_F";
              object.scale.z = this.context.slabWidth/100 + 2.2;
              object.position.z = this.context.slabDepth/100 + 0.5;
              this.roof.add(object.clone());             
              object.name = "BeamLongRoof_B";
              object.scale.z = this.context.slabWidth/100 + 2.2;
              object.position.z = 0.8;
              this.roof.add(object.clone());          
            break;
            case 'Column':
              // object.material = this.materials.concrete;
              object.name = "Column_LB";
              object.position.x = this.context.slabWidth/200;
              object.position.z = 1;
              object.scale.x = -10;
              this.floor1.add(object.clone());          
              this.floor2.add(object.clone());          
              this.floor3.add(object.clone());          
              object.name = "Column_RB";
              object.scale.x = 10;
              object.position.x = -this.context.slabWidth/200;
              this.floor1.add(object.clone());          
              this.floor2.add(object.clone());          
              this.floor3.add(object.clone());          
              object.name = "Column_LF";
              object.scale.x = -10;
              object.position.z = this.context.slabDepth/100 - 2;
              object.position.x = this.context.slabWidth/200;
              this.floor1.add(object.clone());          
              this.floor2.add(object.clone());          
              this.floor3.add(object.clone());          
              object.name = "Column_RF";
              object.scale.x = 10;
              object.position.x = -this.context.slabWidth/200;
              this.floor1.add(object.clone());                 
              this.floor2.add(object.clone());                 
              this.floor3.add(object.clone());                 
            break;
            case 'Beam':
              object.name = "Beam_L";
              object.position.x = -this.context.slabWidth/200;
              object.position.z = 0;
              object.scale.z = this.context.slabDepth/100 + 0.2;
              this.floor1.add(object.clone());          
              this.floor2.add(object.clone());          
              this.floor3.add(object.clone());  
              object.name = "Beam_R";
              object.position.x = this.context.slabWidth/200;
              object.position.z = 0;
              object.scale.z = this.context.slabDepth/100 + 0.2;
              object.scale.x = -10;
              this.floor1.add(object.clone());          
              this.floor2.add(object.clone());          
              this.floor3.add(object.clone());  
              object.name = "BeamRoof_L";
              object.position.x = -this.context.slabWidth/200 - 1.2;
              object.scale.z = this.context.slabDepth/100 + 0.2;
              object.scale.x = 10;
              this.roof.add(object.clone());          
              object.name = "BeamRoof_R";
              object.position.x = this.context.slabWidth/200 + 1.2;
              object.scale.z = this.context.slabDepth/100 + 0.2;
              object.scale.x = -10;
              this.roof.add(object.clone());          
            break;
            case 'RoofGlass':
              object.material = this.materials.glass;
              object.scale.z = this.context.slabDepth/100;
              object.scale.x = this.context.slabWidth/100 + 2;
              this.roof.add(object.clone()); 
            break;
            case 'Net':
              object.scale.z = this.context.slabDepth/100 - 2;
              object.position.y = 0.5;
              object.scale.x = this.context.slabWidth/100 - 2;
              this.floor1.add(object.clone()); 
              this.floor2.add(object.clone()); 
              this.floor3.add(object.clone()); 
            break;
            case 'BeamSmall':
              object.name = 'BeamSmall_L';
              object.scale.z = this.context.slabDepth/200 - 0.5;
              object.position.x = -this.context.slabWidth/500;
              this.roof.add(object.clone()); 
              object.name = 'BeamSmall_R';
              object.position.x = this.context.slabWidth/500;
              this.roof.add(object.clone()); 
              object.name = 'BeamSmall_F';
              object.scale.x = 20;
              object.scale.z = this.context.slabDepth/200 - 0.5;
              object.position.x = -this.context.slabWidth/500;
              object.position.y = 0.3;
              this.floor1.add(object.clone()); 
              this.floor2.add(object.clone()); 
              this.floor3.add(object.clone()); 
              object.name = 'BeamSmall_B';
              object.position.x = this.context.slabWidth/500;
              this.floor1.add(object.clone()); 
              this.floor2.add(object.clone()); 
              this.floor3.add(object.clone()); 
            break;
            case 'Rail':
              object.position.y = 2.5;
              object.position.z = this.context.slabDepth/100;
              object.position.x = -this.context.slabWidth/200 - 0.4;
              object.scale.x = this.context.slabWidth/100 + 0.8;
              object.name = 'Rail_F';
              this.floor1.add(object.clone()); 
              this.floor2.add(object.clone()); 
              this.floor3.add(object.clone()); 
              object.name = 'Rail_L';
              object.scale.x = this.context.slabDepth/100;
              object.rotation.y = Math.PI/2;
              this.floor1.add(object.clone()); 
              this.floor2.add(object.clone()); 
              this.floor3.add(object.clone()); 
              object.name = 'Rail_R';
              object.position.x = this.context.slabWidth/200 + 0.3;
              this.floor1.add(object.clone()); 
              this.floor2.add(object.clone()); 
              this.floor3.add(object.clone()); 
            break;
            case 'RailVert':
              object.position.y = 0;
              object.position.z = this.context.slabDepth/100;
              this.floor1.add(object.clone()); 
              this.floor2.add(object.clone()); 
              this.floor3.add(object.clone()); 
            break;
            case 'Baluster':
              object.position.z = this.context.slabDepth/100;
              object.position.y = 2.7;
              for(let i = 0; i < 31; i++){
                object.name = 'Baluster_' + i;
                object.position.x = 1 * i - this.context.slabWidth/200;
                if(i > this.context.slabWidth/100){
                  object.visible = false;
                } else {
                  object.visible = true;
                }
                this.floor1.add(object.clone()); 
                this.floor2.add(object.clone()); 
                this.floor3.add(object.clone()); 
              }
              object.rotation.y = Math.PI/2;
              for(let i = 0; i < 18; i++){
                object.name = 'BalusterL_' + i;
                object.position.x = -this.context.slabWidth/200 - 0.2;
                object.position.z = 1 * i;
                this.floor1.add(object.clone()); 
                this.floor2.add(object.clone()); 
                this.floor3.add(object.clone());
                if(i > this.context.slabDepth/110){
                  object.visible = false;
                } else {
                  object.visible = true;
                } 
              }
              for(let i = 0; i < 18; i++){
                object.name = 'BalusterR_' + i;
                object.position.x = this.context.slabWidth/200 + 0.2;
                object.position.z = 1 * i;
                this.floor1.add(object.clone()); 
                this.floor2.add(object.clone()); 
                this.floor3.add(object.clone());
                if(i > this.context.slabDepth/110){
                  object.visible = false;
                } else {
                  object.visible = true;
                } 
              }
            break;
          }
        }
      })      
    }
  };

  computeBoundingBox() {
    let offset = 1.6;
    const boundingBox = new THREE.Box3();
    boundingBox.setFromObject(this.object);
    const center = boundingBox.getCenter(new THREE.Vector3());
    const size = boundingBox.getSize(new THREE.Vector3());
    const maxDim = Math.max(size.x, size.y, size.z);
    const fov = this.camera.fov * (Math.PI / 180);
    let cameraZ = maxDim / 2 / Math.tan(fov / 2);
    cameraZ *= offset;
    // this.camera.position.z = center.z + cameraZ;
    this.camera.position.z = 50;
    const minZ = boundingBox.min.z;
    const cameraToFarEdge = minZ < 0 ? -minZ + cameraZ : cameraZ - minZ;

    this.camera.far = cameraToFarEdge * 3;
    this.camera.lookAt(center);
    this.camera.updateProjectionMatrix();

    let controls = new OrbitControls(this.camera, this.renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.25;
    controls.enableZoom = true;
    controls.zoomSpeed = 0.1;
    controls.enableKeys = false;
    controls.screenSpacePanning = false;
    controls.enableRotate = true;
    controls.autoRotate = false;
    controls.dampingFactor = 1;
    controls.autoRotateSpeed = 1.2;
    controls.enablePan = false;
    controls.minPolarAngle = Math.PI / 3;
    controls.maxPolarAngle = Math.PI / 2;
    controls.minAzimuthAngle = -Math.PI / 2 + 0.1;
    controls.maxAzimuthAngle = Math.PI / 2 - 0.1;
    
    this.scene.add(this.floor1)
    this.floor2.position.y = 30;
    this.scene.add(this.floor2);
    this.floor3.position.y = 60;
    this.scene.add(this.floor3)
    this.roof.position.y = 29;
    this.scene.add(this.roof)

    controls.target.set(center.x, center.y + 10, center.z);
    controls.update();
    this.controls = controls;
    this.renderer.setSize(this.width, this.height);
    this.canvas = this.renderer.domElement;
    this.container.appendChild(this.renderer.domElement);
    this.start();
  }

  start() {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  }

  renderScene() {
    this.renderer.render(this.scene, this.camera);
  }

  animate() {
    this.frameId = requestAnimationFrame(this.animate);
    this.controls.update();
    this.renderScene();
  }

  stop() {
    cancelAnimationFrame(this.frameId);
  }

  handleWindowResize() {
    let width = window.innerWidth;
    let height = window.innerHeight;
    this.canvas.style.width = width + 'px';
    this.canvas.style.height = height + 'px';
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
  }

  componentWillUnmount() {
    this.stop();
    this.destroyContext();
  }

  destroyContext() {
    this.container.removeChild(this.renderer.domElement);
    this.renderer.forceContextLoss();
    // this.renderer.context = null;
    this.renderer.domElement = null;
    this.renderer = null;
  }

  render() {
    const width = "100%";
    const height = "100%";
    return (
      <div
        ref={(container) => {
          this.container = container;
        }}
        style={{
          width: width,
          height: height,
          position: "absolute",
          overflow: "hidden",
        }}></div>
    );
  }
}

export default Scene;
